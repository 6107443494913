 #googleloginbutton{
    border: 0px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 2px;
    padding: 1px;
    font-size: 15px;
    font-weight: bold;
    outline: none;
    box-shadow: none;
    cursor: pointer;
    background-clip: padding-box;
    transition: opacity .2s linear;
    width: 268px;
    color:white;
    background-color: #4285F4;
    .buttontext{
        padding-right: 45px;
      }
  }

.opponentContainer{
    margin-left: 30px;
    margin-right:30px;
    display: flex;
    flex-direction: column;
    width: 150px;
}

.opponentContainer__opponentDescription{
    display: flex;
    width: 150px;
    height: 50%;
    flex-direction: column;
    align-items: center;
    
    
    background: #e9eae7;
    font-size: 15px;
    padding: 0;
    word-break: break-all;
    word-wrap: break-word;
    border: 2px solid rgb(197, 182, 255);
    box-sizing: border-box;
}

/* Player Listing */
.opponentContainer__opponentDescription__Invitation{
    justify-content: space-evenly;
    width: 100%;
    height: 100%;
    background: rgb(89, 141, 201);
    font-size:15 px;
}
.opponentContainer__opponentDescription_diffButtons{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.opponentContainer__opponentDescription_diffButtons_dot {
    height: 25px;
    width: 20px;
    background-color: #a9a7a7;
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
  }
.opponentContainer__opponentDescription_diffButtons_dot_highlight {
    height: 25px;
    width: 20px;
    background-color:rgb(233, 113, 113);
    border-radius: 50%;
    text-align: center;
    line-height: 25px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
}
.opponentContainer__opponentDescription__playersbutton{
    width: 90%;
    height: 30px;
    font-size: 14px;
    font-weight: bold;
    margin:0 auto;
    background:#abbebb;
    text-transform: uppercase;
    cursor: pointer;
    border-radius: 6px;
    outline: none;
}
.opponentContainer__opponentDescription__playersbutton:hover{
    transition: all .5s;
    transform: translateY(-1px);
    box-shadow: 5px 5px;
  }

/* Invitation Display */
.opponentContainer__opponentDescription__invitation{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 100%;

.opponentContainer__opponentDescription__invitation__buttons{
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-size: 35px;
    cursor: pointer;
    .opponentContainer__opponentDescription__invitation__button-accept-invitation{
        color: rgb(27, 133, 23);
    }
    
    .opponentContainer__opponentDescription__invitation__button-decline-invitation{
        color: rgb(226, 4, 4);
    }
  }
}
/* Pre Game */
.opponentContainer__opponentDescription__Timer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}

/* Game Play */
.opponentContainer__opponentDescription__GamePlay{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    height: 100%;
}
.opponentContainer__opponentDescription__GamePlay__linescleared{
    color: rgb(56, 29, 212);
    font-weight: 900;
    font-size: 18px;
}
.opponentContainer__opponentDescription__GamePlay__gamesplayed{
    color: rgb(32, 122, 62);
    font-weight: 900;
    font-size: 18px;
}

/* Game Over */ 
/* Misc shared*/ 
.loading{
    margin:0 auto;
    width: 25px;
    height: 25px;
    border: 10px solid black;
    border-top-color: #1a6aed;
    border-bottom-color: #f49b42;
    border-radius: 50%;
    animation-name: picloading;
    animation-duration: 5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.writing{
    color: #19048b;
    font-weight: 900;
    font-size: 18px;
}

.timercountdown{
    color: #652222;
    font-weight: 900;
    font-size: 14px;
    text-transform: uppercase;
}

.opponentContainer__opponentDescription__invitation_reload{
    color: #58580e;
    font-size: 50px;
    cursor: pointer;
  }
.opponentContainer__opponentDescription__invitation_reload:hover{
    transition: all 1s;
    transform: scale(1.15);
}
.opponentContainer__opponentDescription__invitation__button-accept-invitation:hover,
.opponentContainer__opponentDescription__invitation__button-decline-invitation:hover{
    transition: all 1s;
    transform: scale(1.5);
}
@keyframes picloading {
    0%{
      transform: rotate(0deg) scale(1);
    }
    50%{
      transform: rotate(360deg) scale(1.05);
    }
    100%{
      transform: rotate(720deg) scale(1);
    }
  }