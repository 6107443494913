.controls{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width:auto;
    background: #e7e2e2;
    margin-right: 5%;
    color: rgb(7, 44, 53);
    font-weight: 900;
    text-transform: uppercase;
  }
.controls__multiplayer{
  color:rgb(170, 38, 247);
  font-size: 40px;
  cursor: pointer;
}
.controls__raise{
  border-radius: 10px;
  height:40px;
  font-size:20px;
  width:80%;
  cursor:pointer;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-transform: uppercase;
  outline: none;
}

.controls__socket{
  color: darkred;
  font-weight: 900;
  font-size: 10px;
  text-transform: uppercase;
  display: none;
}

.controls__resetPause{
  display: flex;
  justify-content: space-around;
  width:100%;
}
.controls__resetPause__reset{
  color: rgb(59, 9, 153);
  font-size: 40px;
  cursor: pointer;
}

.controls__resetPause__pause{
  color:red;
  font-size: 40px;
  cursor: pointer;
}

.controls__resetPause__play{
  color:green;
  font-size: 40px;
  cursor: pointer;
}



.controls__resetPause__reset:hover, .controls__resetPause__pause:hover, .controls__resetPause__play:hover, .controls__multiplayer:hover {
  transition: all 1s;
  transform: scale(1.1);
}

.controls__raise:hover{
  transition: all 1s;
  transform: translateY(-3px);
  box-shadow: 5px 10px;
}
