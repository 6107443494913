#footer{
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 42px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(207, 214, 207);
    font-size: .9em;
    font-weight: bold;
    color: rgb(104, 100, 100);
    #github{
      text-decoration: none;
      color: #4078c0;
      margin: 10px;
      cursor: pointer;
    }
  }